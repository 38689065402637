import React from "react"

import { Image, Container, Row } from 'react-bootstrap'

const ContainerizedImage = ({ src, maxWidth, className }) => {
    return (
        <Container fluid="md">
            <Row>
                <Image style={{ maxWidth: maxWidth, marginBottom: `1.45rem` }} className={className} src={src} />
            </Row>
        </Container>
    )
}

export default ContainerizedImage